import * as React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';
import { useSidebarContext } from '../../context/sidebarContext';

const imageData = {
  Introduction: '/landing-page/icons/introduction.svg',
  'Navigating Through UI': '/landing-page/icons/navigation-ui.svg',
  Dashboard: '/landing-page/icons/dashboard.svg',
  Reports: '/landing-page/icons/report.svg',
  'Add a New Project': '/landing-page/icons/new-project.svg',
  Tags: '/landing-page/icons/tags.svg',
  'Data Elements': '/landing-page/icons/data-element.svg',
  Providers: '/landing-page/icons/provider.svg',
  Triggers: '/landing-page/icons/trigger.svg',
  'Qualification Criteria': '/landing-page/icons/qualification.svg',
  Transformers: '/landing-page/icons/transformers.svg',
  Publish: '/landing-page/icons/publish.svg',
  'Live Debugging': '/landing-page/icons/debugging.svg',
  'Data Governance': '/landing-page/icons/governance.svg',
  Environments: '/landing-page/icons/states.svg',
  Settings: '/landing-page/icons/settings.svg',
  Alarms: '/landing-page/icons/alarm.svg',
  "TAG Manager": '/landing-page/icons/tagmanagers.svg',
  "Customer Data Platform": '/landing-page/icons/customerdataplatform.svg',
  "Site Performance": '/landing-page/icons/siteperformance.svg',
};
const getImageForTitle = (title) => {
  // Check if the title exists in imageData
  if (title in imageData) {
    return imageData[title]; // Return the corresponding image path
  } else {
    return null;
  }
};

const TreeNode = ({ className = '', url, title, items, ...rest }) => {
  const { collapsed, toggle } = useSidebarContext();
  const isCollapsed = collapsed[url];

  const collapse = () => {
    toggle(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof window !== 'undefined') {
    location = window.location;
  }
  const active =
    location && (location.pathname === url || location.pathname === url + config.gatsby.pathPrefix);

  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  React.useEffect(() => {
    toggle(location?.pathname);
  }, [location?.pathname]);

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link to={url} onClick={collapse}>
          {getImageForTitle(title) && (
            <div className="circular-container">
              <img src={getImageForTitle(title)} alt={title} />
            </div>
          )}
          {title}
          {title && hasChildren ? (
            <button aria-label="collapse" className="collapser">
              {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : null}
        </Link>
      )}
      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode key={item.url + index.toString()} {...item} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
