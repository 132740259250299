import React from 'react';

const HomeBanner = () => {
  return (
    <div className="landing-container">
      <div className="container">
        <h1 className="name">Ingest Labs</h1>
        <p>
        This documentation portal explains how to ingest, manage, orchestrate, and onboard Ingest Labs on to your website.
        </p>
      </div>
    </div>
  );
};

export default HomeBanner;
