import { Link } from 'gatsby';
import React from 'react';
import { useSidebarContext } from '../../context/sidebarContext';

const imageData = {
  aboutingestlabs: '/landing-page/icons/introduction.svg',
  navigatingthroughui: '/landing-page/icons/navigation-ui.svg',
  dashboard: '/landing-page/icons/dashboard.svg',
  reports: '/landing-page/icons/report.svg',
  addanewproject: '/landing-page/icons/new-project.svg',
  tags: '/landing-page/icons/tags.svg',
  dataelements: '/landing-page/icons/data-element.svg',
  providers: '/landing-page/icons/provider.svg',
  triggers: '/landing-page/icons/trigger.svg',
  qualificationcriteria: '/landing-page/icons/qualification.svg',
  transformers: '/landing-page/icons/transformers.svg',
  publish: '/landing-page/icons/publish.svg',
  livedebugging: '/landing-page/icons/debugging.svg',
  datagovernance: '/landing-page/icons/governance.svg',
  environments: '/landing-page/icons/states.svg',
  settings: '/landing-page/icons/settings.svg',
  alarms: '/landing-page/icons/alarm.svg',
  tagmanager: '/landing-page/icons/tagmanagers.svg',
  customerdataplatform: '/landing-page/icons/customerdataplatform.svg',
  siteperformance: '/landing-page/icons/siteperformance.svg',
};

const MdxCard = ({ data }) => {
  return (
    <Link to={data.url}>
      <div className="card landing-card">
        <div className="card-body">
          <div className="d-flex gap-3 align-items-center">
            <div className="imgWrapper">
              <img
                className="imgicon"
                alt={data.label}
                src={imageData[data.label]}
                width={30}
                height={30}
              />
            </div>
            <h5 className="card-title mb-0"> {data.title}</h5>
          </div>
        </div>
      </div>
    </Link>
  );
  // <div className="card">
  //   <div className="card-body">
  //     <div className="d-flex gap-4 align-items-center">
  //       <img alt={data.label} src={imageData[data.label]} width={40} />
  //       <Link to={data.url}>
  //         {' '}
  //         <h5 className="card-title"> {data.title}</h5>
  //       </Link>
  //     </div>
  //   </div>
  // </div>
};

export function CardComp() {
  const { urlObject } = useSidebarContext();

  return (
    <>
      <div className="row">
        {urlObject?.items?.map((item, i) => (
          <div key={i} className="col-md-6 col-lg-4 mb-5 ">
            <MdxCard data={item} />
          </div>
        ))}
      </div>
    </>
  );
}
