const config = {
  gatsby: {
    pathPrefix: '/',
    trailingSlash: false,
  },

  sidebar: {
    ignoreIndex: true,
  },
};

module.exports = config;
